import React from 'react';
import Header from 'js/components/Header';
import style from './style.css';

export default () => {
    return (
        <div className={style.questHeader}>
            <Header allowSwitchRoles={false} />
        </div>
    );
};
