import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { publicRoutes } from '../Header';
import logo from './footer_logo.svg';

const hostName = window.location.hostname;

const Footer = props => {
    return (
        <div className={style.footer}>
            <div className={style.topfooter}>
                <div className={props.className}>
                    <div className="row">
                        <div className="col-12 col-md-3 col-sm-4">
                            <div className={style.logo}>
                                <img src={logo} alt="Logo: Maryland Behavioral Health Online Training" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-sm-4">
                            <address className={style.address}>
                                <p>737 West Lombard Street 4th Floor</p>
                                <p>Baltimore, MD 21201</p>
                                <p>ncsmh@som.umaryland.edu</p>
                            </address>
                        </div>
                        <div className="col-12 col-md-6 col-sm-4">
                            <div className={style.rightcolumn}>
                                <ul className={style.stacked}>
                                    <div className={style.links}>{publicRoutes}</div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.bottomfooter}>
                <div className={props.className}>
                    <div className={style.wrapper}>
                        <div>
                            Website and software produced by{' '}
                            <a
                                href={`//www.3cisd.com?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={style.footerLink}
                            >
                                3C Institute
                            </a>
                            . &copy; {props.copyrightYear} University of Maryland. All Rights Reserved.
                        </div>
                        {props.showPolicyLinks && (
                            <div>
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={style.footerLink}
                                >
                                    Terms of Use
                                </a>
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={style.footerLink}
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div className={props.className}>
                    <p className={style.bottomline}>
                        3C Institute services are funded in part by contracts from The National Institute of Mental
                        Heatlth: HSSN 278200443100C, HSSN 271200774100C, HSSN 43MH32060, HSSN 278200444060C
                    </p>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    copyrightYear: new Date().getFullYear(),
};

export default Footer;
