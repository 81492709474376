import React from 'react';
import { useLocation } from 'cccisd-react-router';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer';
import style from './style.css';
import Header from '../../components/Header';

const MainLayout = props => {
    const { children, className } = props;
    const location = useLocation();

    return (
        <div className={style.wrapper}>
            <Header className={className} />
            <div className={style.body}>
                {location.pathname === '/register'}
                <div className={className}>{children}</div>
            </div>

            <div className={style.footer}>
                <Footer className={className} />
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    showSecondaryNav: PropTypes.bool,
};

MainLayout.defaultProps = {
    className: 'container',
    showSecondaryNav: false,
};

export default MainLayout;
