import React, { useState } from 'react';
import _get from 'lodash/get';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import notification from 'cccisd-notification';
import Modal from 'cccisd-modal';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const ResetProgress = props => {
    const { actingPawnId, actingPawnHash, basePath, parentDeploymentId, settings, yccParentDeploymentId } = props;
    const [isLoading, setIsLoading] = useState(false);

    // Copied from cccisd-laravel-assignment/src/components/navigations/courseWrapper/Mediator/Certificate/index.js
    async function resetCourseProgress() {
        setIsLoading(true);
        const resp = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: parentDeploymentId,
                pawnId: actingPawnId,
                hash: actingPawnHash,
            }),
            {
                params: {
                    isResetCourse: true,
                    isPawnHash: true,
                    forceRepeat: true,
                },
            }
        );

        const subRelatedDeploymentId = _get(resp, 'data.data.deploymentId', '');
        if (_get(resp, 'data.status', '') !== 'success' || !subRelatedDeploymentId) {
            notification({ message: 'Error resetting progress.', type: 'danger', data: resp.data });
            return;
        }

        const options = _get(settings, 'curriculumOptions', []) || [];
        const deploymentIdsToReset = options.map(opt => opt.deploymentId);

        if (yccParentDeploymentId) {
            deploymentIdsToReset.push(yccParentDeploymentId);
        }

        await Promise.all(
            deploymentIdsToReset.map(depId =>
                axios.get(
                    Boilerplate.route('api.assignmentDeployment.repeated', {
                        deploymentId: depId,
                        pawnId: actingPawnId,
                        hash: actingPawnHash,
                    }),
                    {
                        params: {
                            isResetCourse: true,
                            isPawnHash: true,
                            relatedDeploymentId: subRelatedDeploymentId,
                        },
                    }
                )
            )
        );

        // do full page refresh so that all state gets reset with new deployment info
        window.location = basePath;
    }

    function renderConfirm({ closeModal }) {
        return (
            <div>
                <p>
                    Are you sure you want to reset your progress for The Youth Care Coordinator Training? A summary of
                    your prior progress will remain visible on your YCC Transcript.
                </p>
                <div className={style.buttonRow}>
                    <ClickButton
                        className="btn btn-default"
                        onClick={() => closeModal()}
                        isDisabled={isLoading}
                        title="Cancel"
                    />
                    <ClickButton
                        className="btn btn-primary"
                        onClick={resetCourseProgress}
                        isLoading={isLoading}
                        title="Yes, reset progress"
                    />
                </div>
            </div>
        );
    }

    return (
        <Modal
            title="Are you sure?"
            trigger={
                <button className="btn btn-default" type="button">
                    Reset Account and Take Knowledge Check
                </button>
            }
            render={renderConfirm}
        />
    );
};

export default ResetProgress;
