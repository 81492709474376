import React from 'react';
import { RegisterLoginForm } from 'cccisd-laravel-nexus';
import { useLocation } from 'react-router-dom';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const Register = () => {
    const redirectUrl = new URLSearchParams(useLocation().search).get('redirectUrl');
    let onRegisterSuccess;
    let onLoginSuccess;
    if (redirectUrl) {
        onRegisterSuccess = async values => {
            const loginResponse = await axios.post(Boilerplate.route('api.nexus.login'), {
                username: values.username,
                password: values.password,
            });

            if (loginResponse.status !== 200) {
                notification({
                    message: 'Registration was successful, but you could not login at this time.',
                    type: 'danger',
                    duration: 5000,
                });
                return;
            }

            if (loginResponse.data.status === 'success') {
                // if siteRegistrationSurvey is required, check for it here before redirecting
                window.location = redirectUrl;
            } else if (loginResponse.data.status === 'error') {
                notification({
                    message: 'Registration was successful, but you could not login at this time.',
                    type: 'danger',
                    duration: 5000,
                    data: loginResponse.data.errors,
                });
            }
        };

        if (redirectUrl !== 'https://mdbehavioralhealth.com/training') {
            const courseWhitelist = ['learner', 'uberadmin'];
            onLoginSuccess = async response => {
                if (!courseWhitelist.includes(response.data.data.current_pawn.role.handle)) {
                    window.location = Boilerplate.url('/restricted');
                }
                // Timeout is needed to ensure that redirect is finished
                // before the default login redirect tries to occur
                await new Promise(res => setTimeout(res, 3000));
            };
        }
    }

    const registerProps = {
        title: 'Register',
        role: 'learner',
        showPasswordFields: true,
        validateAdditionalFields: values => {
            let errors = {};
            if (!values.first_name) {
                errors.first_name = 'First name is required.';
            }
            if (!values.last_name) {
                errors.last_name = 'Last name is required.';
            }
            return errors;
        },
        onRegisterSuccess,
    };
    const loginProps = {
        title: 'Login',
        onSuccess: onLoginSuccess,
        defaultRedirectUrl: redirectUrl || undefined, // Must explicitly set to undefined to use defaultProp value
    };
    return (
        <div>
            <RegisterLoginForm registerProps={registerProps} loginProps={loginProps} />
        </div>
    );
};

export default Register;
