import React from 'react';
import { Switch } from 'react-router-dom';
import defaultProps from 'recompose/defaultProps.js';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import { AdminRoutes, Dashboard as AppdefDashboard, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import Register from './pages/Register';
import Course from './pages/Course';
import Restricted from './pages/Restricted';
import Training from './pages/Training/Training';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayoutFluid by default
const MainLayoutNoSecondaryNav = defaultProps({
    className: 'container',
    showSecondaryNav: false,
})(MainLayout);
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutNoSecondaryNavFluid = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: false,
})(MainLayout);

const AppRoute = defaultProps({ layout: MainLayoutFluid })(Route);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const match = {
    AppdefDashboard,
    AppdefManage,
    Welcome,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutNoSecondaryNav,
    MainLayoutNoSecondaryNavFluid,
    QuestLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutNoSecondaryNavFluid}
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact layout={MainLayoutNoSecondaryNav} />
        <AppRoute path="/welcome" component={Welcome} exact layout={MainLayoutNoSecondaryNav} />
        <AppRoute path="/register" component={Register} exact layout={MainLayoutNoSecondaryNav} />
        <AppRoute path="/course" component={Course} layout={MainLayoutNoSecondaryNav} />
        <AppRoute path="/restricted" component={Restricted} layout={MainLayoutNoSecondaryNav} />

        <AppRoute
            path={['/quest/preview', '/d', '/survey']}
            component={Quest}
            layout={QuestLayout}
            componentProps={{ registrationUrlsByRole: { learner: '/register' } }}
        />

        <AppRoute path="/online-training" component={Training} layout={MainLayoutNoSecondaryNav} />
        <AppRoute
            path="/pg/training"
            component={Training}
            componentProps={{ handle: 'problemGambling' }}
            layout={MainLayoutNoSecondaryNav}
        />

        {AdminRoutes(MainLayoutNoSecondaryNavFluid)}

        <AppRoute path="/account" component={Nexus} layout={MainLayoutNoSecondaryNav} />
        <AppRoute
            path={Fortress.settings.config.after_login_url}
            component={AuthRedirect}
            layout={MainLayoutNoSecondaryNav}
            exact
        />

        {/* Not found page */}
        <AppRoute component={NotFound} layout={MainLayoutNoSecondaryNav} />
    </Switch>
);
