import React, { useState } from 'react';
import axios from 'cccisd-axios';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';

const Boilerplate = window.cccisd.boilerplate;

const TakeKnowledgeCheck = ({
    actingPawnId,
    actingPawnHash,
    deploymentId,
    getKnowledgeCheckProgress,
    isPreviewMode,
    kcParentDeploymentId,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [nextKCDeploymentId, setNextKCDeploymentId] = useState(null);

    async function getNextKCDeploymentId() {
        if (!kcParentDeploymentId) {
            setIsLoading(false);
            return;
        }

        const resp = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: kcParentDeploymentId,
                pawnId: actingPawnId,
                hash: actingPawnHash,
            }),
            {
                params: {
                    isPawnHash: true,
                    relatedDeploymentId: deploymentId,
                },
            }
        );

        setNextKCDeploymentId(resp?.data?.data?.deploymentId);
        setIsLoading(false);
    }

    return (
        <Modal
            size="large"
            title="Youth Care Coordination Knowledge Check"
            trigger={
                <button className="btn btn-primary" type="button">
                    Take Knowledge Check
                </button>
            }
            beforeShow={() => setIsLoading(true)}
            afterShow={getNextKCDeploymentId}
            afterClose={() => getKnowledgeCheckProgress()}
            render={({ closeModal }) => {
                if (isLoading) {
                    return <Loader loading type="inline" />;
                }

                return (
                    <DeploymentPlayer
                        deploymentId={nextKCDeploymentId}
                        pawnId={actingPawnId}
                        pawnHash={actingPawnHash}
                        disableLayout
                        isPreviewMode={isPreviewMode}
                    />
                );
            }}
        />
    );
};

export default TakeKnowledgeCheck;
