import React from 'react';
import IconNewTab from 'cccisd-icons/new-tab';
import Modal from 'cccisd-modal';
import Table from 'cccisd-graphql-table';

import Transcript from '../Transcript';
import style from './style.css';
import adminQueryScoped from './adminScoped.graphql';
import adminQueryNonScoped from './adminNonScoped.graphql';

const Fortress = window.cccisd.fortress;

const Admin = ({ isPreviewMode, mediatorProps, yccAgency }) => {
    function renderTranscriptColumn({ value, row }) {
        return (
            <span>
                <Transcript
                    pawnId={value}
                    isPreviewMode={isPreviewMode}
                    mediatorProps={mediatorProps}
                    modalTitle={`YCC Transcript - ${row['pawn.user.firstName']} ${row['pawn.user.lastName']}`}
                    trigger={
                        <button className="btn btn-xs btn-default" type="button">
                            <IconNewTab />
                        </button>
                    }
                />
            </span>
        );
    }

    // formats YYYY-MM-DD into MM/DD/YY
    function formatHireDate({ value }) {
        if (!value) {
            return <span />;
        }

        return <span>{`${value[5]}${value[6]}/${value[8]}${value[9]}/${value[2]}${value[3]}`}</span>;
    }

    function renderTrigger() {
        return (
            <button type="button" className="btn btn-xs btn-link">
                YCC Admin
            </button>
        );
    }

    function renderModal({ closeModal }) {
        const tableProps = {
            name: 'yccAdmin',
            query: ['uberadmin', 'questprojectadmin'].includes(Fortress.user.acting.role.handle)
                ? adminQueryNonScoped
                : adminQueryScoped,
            graphqlVariables: { yccAgency },
            rowKey: 'assignmentProgressId',
            noRecordsMessage: isPreviewMode ? 'No records available in preview mode' : 'No records to show',
            columns: [
                { name: 'pawn.user.firstName', label: 'First Name', sort: true, filter: true },
                { name: 'pawn.user.lastName', label: 'Last Name', sort: true, filter: true },
                { name: 'pawn.user.email', label: 'Email', sort: true, filter: true },
                {
                    name: 'devTags.ycc_role',
                    label: 'Role',
                    sort: true,
                    filter: true,
                },
                {
                    name: 'devTags.ycc_agency',
                    label: 'Agency',
                    sort: true,
                    filter: true,
                },
                {
                    name: 'devTags.ycc_jurisdiction',
                    label: 'Jurisdiction',
                    sort: true,
                    filter: true,
                },
                {
                    name: 'completedDate',
                    label: 'Register Date',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'devTags.ycc_start_date',
                    label: 'Hire Date',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                    render: formatHireDate,
                },
                {
                    name: 'pawn.pawn.pawnId',
                    label: 'Transcript',
                    render: renderTranscriptColumn,
                    className: 'text-center',
                },
                {
                    name: 'pawn.last_kc.completedDate',
                    label: 'Last Knowledge Check',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_a1.completedDate',
                    label: 'Cluster A1',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_a2.completedDate',
                    label: 'Cluster A2',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_a3.completedDate',
                    label: 'Cluster A3',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_a4.completedDate',
                    label: 'Cluster A4',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_b1.completedDate',
                    label: 'Cluster B1',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_b2.completedDate',
                    label: 'Cluster B2',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_b3.completedDate',
                    label: 'Cluster B3',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_b4.completedDate',
                    label: 'Cluster B4',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_c1.completedDate',
                    label: 'Cluster C1',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_c2.completedDate',
                    label: 'Cluster C2',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_c3.completedDate',
                    label: 'Cluster C3',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_c4.completedDate',
                    label: 'Cluster C4',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_d1.completedDate',
                    label: 'Cluster D1',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_d2.completedDate',
                    label: 'Cluster D2',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_d3.completedDate',
                    label: 'Cluster D3',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_d4.completedDate',
                    label: 'Cluster D4',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_e1.completedDate',
                    label: 'Cluster E1',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_e2.completedDate',
                    label: 'Cluster E2',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
                {
                    name: 'pawn.last_e3.completedDate',
                    label: 'Cluster E3',
                    sort: true,
                    filter: true,
                    filterSettings: { type: 'date' },
                },
            ],
        };

        return (
            <div>
                <div className={style.table}>
                    <Table {...tableProps} />
                </div>
                <button className="btn btn-primary" onClick={() => closeModal()} type="button">
                    Close
                </button>
            </div>
        );
    }

    return <Modal title="YCC Admin" size="95vw" trigger={renderTrigger()} render={renderModal} />;
};

export default Admin;
