import React, { useState, useEffect } from 'react';
import { isAfter, isBefore, subYears } from 'date-fns';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import DefaultMediator from 'cccisd-laravel-assignment/dist/components/navigations/curriculum/Mediator/index.js';

import Admin from './Admin';
import Transcript from './Transcript';
import ResetProgress from './ResetProgress';
import TakeKnowledgeCheck from './TakeKnowledgeCheck';
import registrationQuery from './registration.graphql';
import knowledgeCheckQuery from './knowledgeCheck.graphql';
import messages from './message.json';
import style from './style.css';

const Fortress = window.cccisd.fortress;

// not used anymore because we want behavior of 2nd-year hires to be same as veteran hires
// still keeping it here for posterity
// const veteranHireCutoff = new Date('2018-07-02 00:00:00');

const newHireCutoff = subYears(new Date(), 1);

const YccMediatorRoot = props => {
    const { actingPawnId, deploymentId: relatedDeploymentId, isPreviewMode, mediatorProps } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [registrationAnswers, setRegistrationAnswers] = useState({});
    const [knowledgeCheckData, setKnowledgeCheckData] = useState({});
    const isKnowledgeCheckComplete = !!knowledgeCheckData?.latestProgress?.completedAt;
    const isYccAdmin =
        !!Fortress.user.acting?.role_data?.isYccAdmin ||
        ['uberadmin', 'questprojectadmin'].includes(Fortress.user.acting.role.handle);

    let moduleOrder = ['a', 'b', 'c', 'd', 'e'];
    if (isKnowledgeCheckComplete && !registrationAnswers?.isNewHire) {
        const scoreMapper = {
            a: { correct: 0, total: 0 },
            b: { correct: 0, total: 0 },
            c: { correct: 0, total: 0 },
            d: { correct: 0, total: 0 },
            e: { correct: 0, total: 0 },
        };
        Object.keys(knowledgeCheckData.latestProgress.devTags).forEach(devTag => {
            const cluster = devTag.match(/_(?<cluster>[abcde]{1})[\d]{1}$/)?.groups?.cluster;
            if (!cluster) {
                return; // __typename
            }

            scoreMapper[cluster].total++;
            if (['correct', 1, '1'].includes(knowledgeCheckData.latestProgress.devTags[devTag])) {
                scoreMapper[cluster].correct++;
            }
        });
        Object.keys(scoreMapper).forEach(key => {
            scoreMapper[key].percent = scoreMapper[key].correct / scoreMapper[key].total;
        });

        moduleOrder.sort((mod1, mod2) => {
            return scoreMapper[mod1].percent >= scoreMapper[mod2].percent ? 1 : -1;
        });
    }

    useEffect(() => {
        async function fetchRegistrationAnswers() {
            let isSupervisor = false;
            let isVeteranHire = false;
            let isNewHire = false;
            let yccAgency = '';

            if (!isPreviewMode) {
                const resp = await client.query({
                    query: registrationQuery,
                    variables: { pawnId: actingPawnId },
                    fetchPolicy: 'network-only',
                });

                const data = _get(resp, 'data.roles.anyRole.assignmentProgress.devTags', {}) || {};
                const startDate = new Date(_get(data, 'ycc_start_date', ''));
                yccAgency = _get(data, 'ycc_agency', '');
                isSupervisor = _get(data, 'ycc_role', '') === 'Youth Care Coordinator Supervisor';
                isVeteranHire = !isSupervisor && isBefore(startDate, newHireCutoff);
                isNewHire = !isSupervisor && isAfter(startDate, newHireCutoff);
            }

            setRegistrationAnswers({
                yccAgency,
                isSupervisor,
                isVeteranHire,
                isNewHire,
            });
        }

        fetchRegistrationAnswers();
        getKnowledgeCheckProgress();
    }, []);

    async function getKnowledgeCheckProgress() {
        setIsLoading(true);
        const resp = await client.query({
            query: knowledgeCheckQuery,
            variables: {
                actingPawnId,
                relatedDeploymentId,
            },
            fetchPolicy: 'network-only',
        });

        const parentDeploymentId = resp?.data?.flows?.deployment?.deploymentId;
        const allProgress = resp?.data?.roles?.anyRole?.assignmentProgressList || {};
        let latestProgress;
        if (Array.isArray(allProgress) && allProgress.length > 0) {
            latestProgress = allProgress.sort((a, b) => {
                if (isAfter(new Date(a.completedAt), new Date(b.completedAt))) {
                    return -1;
                }
                return 1;
            })[0];
        }
        setKnowledgeCheckData({ parentDeploymentId, latestProgress });
        setIsLoading(false);
    }

    /* //////////////////////////////////////////////////
    /////// RENDER //////////////////////////////////////
    ////////////////////////////////////////////////// */

    function renderFirstYearObjectivesList(content) {
        return (
            <>
                <h4>{content.heading}</h4>
                <ul className={style.objectivesList}>
                    {content.objectives.map((objective, i) => {
                        let isComplete = false;
                        try {
                            isComplete = mediatorProps.progress.find(
                                dep => dep.assignment.assignmentHandle === objective.assignmentHandle
                            ).assignmentProgress.completed;
                        } catch (e) {
                            console.error(
                                'Could not find progress for assignment handle ' + objective.assignmentHandle
                            );
                        }

                        return (
                            <li key={i + '_' + content.heading} className={isComplete ? style.complete : ''}>
                                {objective.text}
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    }

    function renderRecommendedCluster(cluster) {
        let isComplete = false;
        try {
            isComplete = !mediatorProps.progress.some(
                dep => dep.assignment.assignmentHandle.includes(`_${cluster}`) && !dep.assignmentProgress.completed
            );
        } catch (e) {
            console.error('Could not find progress for cluster ' + cluster);
        }

        return (
            <li key={cluster} className={isComplete ? style.complete : ''}>
                Cluster {cluster.toUpperCase()} {isComplete && <span className="text-success">✓</span>}
            </li>
        );
    }

    function renderMessage() {
        const { isSupervisor, isVeteranHire, isNewHire } = registrationAnswers;
        const isAllModulesComplete = mediatorProps?.progress
            ? mediatorProps.progress.every(deployment => deployment?.assignmentProgress?.completed)
            : false;

        if (isAllModulesComplete) {
            if (isNewHire) {
                return messages.newHire.completedAllModules.split('\n').map((str, i) => <p key={i}>{str}</p>);
            }

            return (
                <div>
                    {messages.completedAllModules.split('\n').map((str, i) => (
                        <p key={i}>{str}</p>
                    ))}
                    <div className={style.resetProgress}>
                        <ResetProgress {...props} yccParentDeploymentId={knowledgeCheckData?.parentDeploymentId} />
                    </div>
                </div>
            );
        }

        if (!isKnowledgeCheckComplete && (isSupervisor || isVeteranHire)) {
            return (
                <div className={style.needsKnowledgeCheck}>
                    <p>{messages.needsKnowledgeCheck}</p>
                    <div className={style.alignRight}>
                        <TakeKnowledgeCheck
                            {...props}
                            getKnowledgeCheckProgress={getKnowledgeCheckProgress}
                            kcParentDeploymentId={knowledgeCheckData?.parentDeploymentId}
                        />
                    </div>
                </div>
            );
        }

        if (isNewHire) {
            return (
                <div>
                    <h4>{messages.newHire.instructions}</h4>
                    {renderFirstYearObjectivesList(messages.newHire.first30Days)}
                    {renderFirstYearObjectivesList(messages.newHire.first90Days)}
                    {renderFirstYearObjectivesList(messages.newHire.first180Days)}
                </div>
            );
        }

        return (
            <>
                <p>{messages.needsCluster.aboveCluster}</p>
                <ol className={style.clusterList}>{moduleOrder.map(renderRecommendedCluster)}</ol>
                <p className={style.noMargin}>{messages.needsCluster.belowCluster}</p>
            </>
        );
    }

    /* //////////////////////////////////////////////////
    /////// MAIN ////////////////////////////////////////
    ////////////////////////////////////////////////// */

    if (isLoading || !props?.mediatorProps?.progress?.length) {
        return (
            <div className={style.inlineLoader}>
                <Loader loading type="inline" />
            </div>
        );
    }

    try {
        mediatorProps.progress.sort((a, b) => {
            const clusterA = a.assignment.assignmentHandle.match(/_(?<cluster>[abcde]{1})[\d]{1}$/).groups.cluster;
            const clusterB = b.assignment.assignmentHandle.match(/_(?<cluster>[abcde]{1})[\d]{1}$/).groups.cluster;
            if (clusterA === clusterB) {
                return 0;
            }
            return moduleOrder.indexOf(clusterA) - moduleOrder.indexOf(clusterB);
        });
    } catch (e) {
        // meh *shrugs* so what, they'll be out of order
    }

    return (
        <div>
            <div className={style.dataButtons}>
                {isYccAdmin && (
                    <Admin
                        isPreviewMode={isPreviewMode}
                        mediatorProps={mediatorProps}
                        yccAgency={registrationAnswers?.yccAgency}
                    />
                )}
                {<Transcript pawnId={actingPawnId} isPreviewMode={isPreviewMode} mediatorProps={mediatorProps} />}
            </div>
            <div className={style.message}>{renderMessage()}</div>
            <DefaultMediator {...props} />
        </div>
    );
};

export default YccMediatorRoot;
