import React from 'react';
import PropTypes from 'prop-types';
import { ResourceCenter } from 'cccisd-laravel-resources';

const Training = ({ handle }) => {
    return <ResourceCenter handle={handle} />;
};

Training.defaultProps = {
    handle: 'CourseList(Public)',
};

Training.propTypes = {
    handle: PropTypes.string,
};

export default Training;
