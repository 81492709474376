import React from 'react';
import style from './style.css';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import Lock from 'cccisd-icons/lock3';
import BackArrow from 'cccisd-icons/arrow-left6';

const Restricted = () => {
    const roles = _get(window, 'cccisd.appDefs.pawn.roles', []);
    const learnerLabel = roles.find(role => role.handle === 'learner').label;

    return (
        <div>
            <h2>
                <Lock />
                &nbsp;Course Restricted
            </h2>
            <p className={style.p}>
                You must be a {learnerLabel} to access this course. Please <Link to="/register">login</Link> as a
                different user, or contact support via{' '}
                <span className={style.noWrap}>
                    email at <a href="mailto:support@3cisd.com">support@3cisd.com</a>
                </span>{' '}
                or{' '}
                <span className={style.noWrap}>
                    phone at <a href="tel:+19843160406">(984) 316-0406</a>
                </span>
                .
            </p>

            <a href="https://mdbehavioralhealth.com/training">
                <button type="button" className="btn btn-default">
                    <BackArrow /> Return to Trainings
                </button>
            </a>
        </div>
    );
};

export default Restricted;
