import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const Course = props => {
    const endpoint = new URLSearchParams(useLocation().search).get('endpoint');
    const redirectTo = endpoint && Boilerplate.url(`survey/${endpoint}`);
    const roleWhiteList = ['learner', 'uberadmin'];

    // If user is not logged in, redirect them to the Register/Login form
    // Register/Login will then redirect them back to the Quest after submitting
    if (!Fortress.auth()) {
        return (
            <Redirect
                to={{
                    pathname: '/register',
                    search: `?redirectUrl=${redirectTo ||
                        'https://mdbehavioralhealth.com/training'}`,
                }}
            />
        );
    }

    // If user is logged in, but did not specify a courseURL
    // just return to trainings page
    if (!endpoint) {
        window.location = 'https://mdbehavioralhealth.com/training';
        return;
    }

    // If user specifies a courseUrl, but does not have the Learner role
    // Show message explaining why they don't have access
    if (!roleWhiteList.includes(Fortress.user.acting.role.handle)) {
        return <Redirect to="/restricted" />;
    }

    // If user is logged in and a Learner, proceed to the Quest
    window.location = redirectTo || Boilerplate.url('');
};

export default Course;
