import React, { useState } from 'react';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import { Report, Page } from 'cccisd-laravel-report';

import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Table from 'cccisd-table';
import Tabs from 'cccisd-tabs';
import style from './style.css';
import query from './transcript.graphql';

const Transcript = ({ modalTitle, pawnId, isPreviewMode, mediatorProps, trigger }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState({});

    async function getTranscriptData() {
        const resp = await client.query({
            query,
            variables: {
                pawnId,
            },
            fetchPolicy: 'network-only',
        });
        const progress = _get(resp, 'data.roles.anyRole.assignmentProgressList', []) || [];
        const user = _get(resp, 'data.roles.anyRole.user', {}) || {};
        setData(progress);
        setUserData(user);
        setIsLoading(false);
    }

    const sharedTableProps = {
        showPerPageOptions: false,
        rowKey: 'assignmentProgressId',
        columns: [
            {
                name: 'completedDate',
                label: 'Completed',
            },
        ],
        hideShowingRowsSummary: true,
    };

    function renderReport(tableProps) {
        return (
            <div className={style.report}>
                <Report
                    downloadFilename={
                        'Youth Care Coordinator Training Transcript_' +
                        tableProps.title +
                        '_' +
                        new Date().toLocaleDateString()
                    }
                >
                    <Page>
                        <h2>Youth Care Coordinator Training Transcript</h2>
                        <address>
                            <ul className="list-unstyled">
                                <li>
                                    <b>Name:</b>&nbsp;{userData?.fullName}
                                </li>
                                <li>
                                    <b>Email:</b>&nbsp;{userData?.email}
                                </li>
                            </ul>
                        </address>
                        <div className={style.table}>
                            <h4>{tableProps.title}</h4>
                            <Table {...tableProps} />
                        </div>
                    </Page>
                </Report>
            </div>
        );
    }

    function renderModuleProgress() {
        const tableProps = {
            ...sharedTableProps,
            name: 'modules',
            title: 'Modules Completed',
            noRecordsMessage: isPreviewMode
                ? 'No records available in preview mode'
                : 'You have not completed any modules',
            columns: [
                ...sharedTableProps.columns,
                { name: 'displayName', label: 'Module' },
                { name: 'score', label: 'Test Score (%)' },
            ],
            data: data.filter(ap => !ap.assignment.assignmentHandle.includes('kc')).map(mapTableData),
        };

        return renderReport(tableProps);
    }

    function renderKnowledgeCheckProgress() {
        const tableProps = {
            ...sharedTableProps,
            name: 'knowledgeCheck',
            title: 'Knowledge Checks Completed',
            noRecordsMessage: isPreviewMode
                ? 'No records available in preview mode'
                : 'You have not completed any Knowledge Checks',
            columns: [
                ...sharedTableProps.columns,
                { name: 'completion', label: 'Completion #' },
                { name: 'score', label: 'Score (%)' },
            ],
            data: data.filter(ap => ap.assignment.assignmentHandle.includes('kc')).map(mapTableData),
        };

        return renderReport(tableProps);
    }

    function mapTableData(ap, index, tableData) {
        const isKnowledgeCheck = ap.assignment.assignmentHandle.includes('_kc');

        let score;
        let displayName;
        if (isKnowledgeCheck) {
            const scoreObj = Object.keys(ap.devTags).reduce(
                (accumulator, curr) => {
                    if (curr.includes('ycc_knowledgeCheck')) {
                        accumulator.total++;
                        if (['correct', 1, true].includes(ap.devTags[curr])) {
                            accumulator.correct++;
                        }

                        return accumulator;
                    }

                    return accumulator;
                },
                { total: 0, correct: 0 }
            );
            score = Math.round((scoreObj.correct / scoreObj.total) * 100);
        } else {
            const scoreKey = Object.keys(ap.variables).find(k => k.includes('_score'));
            score = scoreKey ? ap.variables[scoreKey] : null;

            try {
                displayName = mediatorProps.progress.find(
                    dep => dep.assignment.assignmentHandle === ap.assignment.assignmentHandle
                ).displayName;
            } catch (e) {
                console.error('Could not find display name for assignment ' + ap.assignment.assignmentHandle);
            }
        }

        return {
            assignmentProgressId: ap.assignmentProgressId,
            completedDate: ap.completedDate,
            displayName,
            score,
            completion: tableData.length - index,
        };
    }

    return (
        <Modal
            title={modalTitle}
            size="large"
            trigger={trigger}
            beforeShow={() => {
                setIsLoading(true);
            }}
            afterShow={() => {
                getTranscriptData();
            }}
            render={({ closeModal }) => {
                if (isLoading) {
                    return <Loader type="inline" loading />;
                }

                const tabList = [
                    { name: 'modules', title: 'Modules', content: renderModuleProgress() },
                    {
                        name: 'knowledgeCheck',
                        title: 'Knowledge Check',
                        content: renderKnowledgeCheckProgress(),
                    },
                ];

                return (
                    <div>
                        <Tabs tabList={tabList} />
                        <button className="btn btn-primary" onClick={() => closeModal()} type="button">
                            Close
                        </button>
                    </div>
                );
            }}
        />
    );
};

Transcript.defaultProps = {
    modalTitle: 'YCC Transcript',
    trigger: (
        <button type="button" className="btn btn-xs btn-link">
            YCC Transcript
        </button>
    ),
};

export default Transcript;
